@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Prompt', sans-serif;
}

.ant-modal-root .ant-modal-content {
  border-radius: 20px !important;
}

.ant-modal-root .ant-modal-header {
  border-radius: 20px 20px 0 0 !important;
}

.ant-modal-root .ant-modal-close-x {
  display: none;
}

.to-image {
  filter: grayscale(80%);
}

.border-primary {
  border-color: #21409A;
}

.outline-none {
  outline: none;
}
